body {
  margin: 0;
  line-height: normal;
}

@media screen and (max-width: 640px) {
  :root {
    --font-size-sm: 14px;
    --font-size-base: 16px;
    --font-size-lg: 18px;
    --font-size-13xl: 32px;
    /* Gaps */
    --gap-base: 16px;
    --gap-21xl: 40px;
    --gap-5xs: 8px;
    --gap-9xs: 4px;
    --gap-5xl: 24px;
    --gap-xl: 20px;

    /* Paddings */
    --padding-13xl: 32px;
    --padding-base: 16px;
    --padding-xl: 20px;
    --padding-7xl: 26px;
    --padding-21xl: 40px;
    --padding-lgi: 19px;
    --padding-3xs: 10px;
    --padding-5xs: 8px;
    --padding-mini: 15px;

    /* Border radiuses */
    --br-13xl: 32px;
    --br-5xl: 24px;
    --br-45xl: 64px;
  }
}
@media (min-width: 641px) and (max-width: 1024px) {
  :root {
    --font-size-base: 16px;
    --font-size-lg: 18px;
    --font-size-41xl: 60px;
    --font-size-17xl: 36px;
    --font-size-29xl: 48px;
    --font-size-sm: 14px;
    --font-size-5xl: 24px;
    --font-size-lgi: 19px;
    --font-size-xl: 20px;
    --font-size-13xl: 32px;
    --font-size-7xl: 26px;

    /* Gaps */
    --gap-61xl: 80px;
    --gap-21xl: 40px;
    --gap-xl: 20px;
    --gap-base: 16px;
    --gap-9xs: 4px;
    --gap-5xl: 24px;
    --gap-5xs: 8px;
    --gap-xs: 12px;

    /* Paddings */
    --padding-13xl: 32px;
    --padding-base: 16px;
    --padding-xl: 20px;
    --padding-7xl: 26px;
    --padding-21xl: 40px;
    --padding-lgi: 19px;
    --padding-3xs: 10px;
    --padding-5xs: 8px;
    --padding-mini: 15px;

    /* Border radiuses */
    --padding-xl: 20px;
    --padding-lg: 18px;
    --padding-13xl: 32px;
    --padding-30xl: 49px;
    --padding-5xl: 24px;
    --padding-base: 16px;
    --padding-10xs: 3px;
    --padding-lgi: 19px;
    --padding-3xs: 10px;
    --padding-5xs: 8px;
    --padding-mini: 15px;

    /* Border radiuses */
    --br-13xl: 32px;
    --br-xl: 20px;
    --br-45xl: 64px;
  }
}
@media screen and (min-width: 1024px) {
  :root {
    --font-size-sm: 14px;
    --font-size-base: 16px;
    --font-size-lg: 18px;
    --font-size-41xl: 60px;
    --font-size-29xl: 48px;
    --font-size-17xl: 36px;
    --font-size-xl: 20px;
    --font-size-5xl: 24px;
    --font-size-lgi: 19px;
    --font-size-19xl: 38px;
    --font-size-10xl: 29px;

    --gap-base: 16px;
    --gap-xs: 12px;
    --gap-21xl: 40px;
    --gap-xl: 20px;
    --gap-9xs: 4px;
    --gap-5xl: 24px;
    --gap-61xl: 80px;
    --gap-9xl: 28px;
    --gap-5xs: 8px;

    /* Paddings */
    --padding-13xl: 32px;
    --padding-xl: 20px;
    --padding-2xl: 21px;
    --padding-lg: 18px;
    --padding-30xl: 49px;
    --padding-61xl: 80px;
    --padding-32xl: 51px;
    --padding-21xl: 40px;
    --padding-5xl: 24px;
    --padding-base: 16px;
    --padding-10xs: 3px;
    --padding-lgi: 19px;
    --padding-3xs: 10px;
    --padding-5xs: 8px;
    --padding-mini: 15px;

    /* Border radiuses */
    --br-13xl: 32px;
    --br-xl: 20px;
    --br-45xl: 64px;
  }
}

:root {
  --font-inter: Inter;

  --color-black: #000;
  --black-60: #666;
  --black-85: #262626;
  --color-gray-100: #231f20;
  --black-90: #1a1a1a;
  --color-gray-200: #131313;
  --black-95: #0d0d0d;
  --color-gray-300: rgba(255, 255, 255, 0.5);
  --color-gray-400: rgba(255, 255, 255, 0.1);
  --color-gray-500: rgba(255, 255, 255, 0.2);
  --color-mintcream: #f7fffa;
  --black-10: #e6e6e6;
  --black-0: #fff;
  --black-20: #ccc;
  --color-crimson: #d20515;
  --color-darkslateblue: #593987;
  --color-red: #d20a0a;
}
